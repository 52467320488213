.App {
  text-align: center;
  font-family: 'Lato', sans-serif;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.ant-carousel .slick-slide img {
  width: 100%;
  height: auto;
}

.card {
  background-color: rgb(77, 77, 77);
  border-radius: 15px;
}

.card-deck {
  margin-left: auto;
  margin-right: auto;
}

.producttitle {
  font-size: 16px;
  padding-bottom: 0;
  margin-bottom: 0;
}

.card-img-top {
  border-radius: 10%;
  margin-bottom: 25px;
  padding-left: 25px;
  padding-right: 25px;
}

/* .card-text {
    height: 9.6rem !important;
    overflow: hidden;
    text-align: left !important;
    font-size: 18px !important;
  } */

.productspage {
  background-color: black;
  color: white;
  text-transform: uppercase;
}

.footer {
  padding: 0;
  padding-top: 50px;
  padding-bottom: 2%;
  background-color: rgb(51, 51, 51);
  color: white;
}

.footertitle {
  padding-bottom: 4%;
}

.footertop {
  padding-bottom: 50px;
}

.about {
  background-color: rgb(51, 51, 51);
  color: white;
  padding-bottom: 50px;
}

.aboutcol {
  max-width: 400px;
  text-align: left;
}

.stjerne {
  max-height: 25px;
  max-width: auto;
  /* padding: 0;
    margin: 0; */
}

.contact {
  background-color: rgb(0, 0, 0);
  color: white;
}

.designyourown {
  background-color: rgb(0, 0, 0);
  color: white;
  padding-bottom: 70px;
}

.form-group {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.cpuform {
  text-align: left;
  background-color: rgb(51, 51, 51);
  border-radius: 7px;
  padding: 20px;
  padding-left: 40px;
}

.summarytable {
  background-color: rgb(255, 255, 255);
  border-radius: 7px;
  padding: 20px;
}

.price {
  background-color: black;
  color: white;
  padding: 5px;
  padding-bottom: 7px;
  border-radius: 12px;
}

.copyright {
  background-color: rgb(24, 24, 24);
  margin: 0;
  padding: 0;
  padding-top: 16px;
  padding-bottom: 3px;
  font-size: smaller;
}

.location {
  line-height: 10px;
}

.boldtext {
  font-weight: 700;
}

.socialicon {
  border: white solid 2px;
  border-radius: 50%;
  padding: 7px 12px 13px 12px;
  font-size: 20px;
  margin-right: 6px;
}

.hideme {
  display: none;
  height: 1px;
}

#makeMeTaller {
  width: 300px;
}

.adminimg {
  max-width: 90px;
  max-width: auto;
  padding: 0;
  margin: 0;
  border: white solid 1px;
}

.carddelete {
  color: white;
  max-width: 450px;
  max-height: auto;
  text-transform: uppercase;
}

.imgrey {
  color: rgb(58, 118, 192);
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: currentColor !important;
  font-weight: 500;
}

.ant-card {
  background-color: rgb(77, 77, 77);
  border-radius: 15px;
  margin-bottom: 10%;
  margin-right: 5%;
}

.ant-card-head {
  padding: 0;
  border-bottom: none;
  color: white;
}

.ant-card-body {
  padding: 3%;
}

.ant-card-head::before {
  outline: none;
  box-shadow: none;
}
