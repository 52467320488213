.navbar_link{
    color: rgb(0, 0, 0);
    text-decoration: none;
  }
  
  .navbar_link:hover{
    text-decoration: none;
    color: rgb(0, 0, 0)
  }
  
  .navbar_link--active{
    color: rgb(58, 58, 58);
  }
  
  .navlist{
    margin-right: 35px;
    font-size: 17px;
  }
  
  ul{
    padding-inline-start: 0;
  }
  
  .navbar {
    background-color: rgb(255, 255, 255);
    /* margin-bottom: 30px; */
    text-transform: uppercase;
    font-weight: 700;
  }

  .logo {
    max-height: 60px;
    max-width: auto;
  }

  .hashlink {
    text-decoration: none;
    color: white !important;
    cursor: default;
  }

  .hashlink:hover {
    text-decoration: none;
    color: white !important;
    cursor: default;
  }
  